const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://bxtuvscnrffs3fva37c4534wma.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mwr4ycycj5gdtlh2ekrkrggapi",
    "aws_content_delivery_bucket": "resaleequipmentporta-20200911115858-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d17lkhmanvaqjp.cloudfront.net",
    "aws_user_files_s3_bucket": "excess-equip-file-storage53732-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5dba25fa-88be-4a73-9904-5ae2918cde9d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PkNlSD87t",
    "aws_user_pools_web_client_id": "2dq583muog0trqqnosjetufmlb",
    "oauth": {
        "domain": "gfmobility.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "https://excessequipment.onegfapps.com/",
        "redirectSignOut": "https://excessequipment.onegfapps.com/",
        "responseType": "token"
    }
};


export default awsmobile;